import React from "react";
import { Panel } from "rc-collapse";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";

import CareerAccordionItem from "./career-accordion-item";

class CareerAccordion extends React.Component {
  onAccordionItemToggle = evt => {};

  makeUUID = name => {
    return name.toLowerCase().replace(" ", "-");
  };
  render() {
    const { roles } = this.props;
    return (
      <section className="section">
        <div
          className="container--bordered career-accordion-wrapper"
          role="tablist"
        >
          <Accordion
            allowZeroExpanded={true}
            className="career-accordion"
            preExpanded={this.props.roleName}
            onChange={this.onAccordionItemToggle}
          >
            {roles.map(role => {
              return (
                <AccordionItem
                  className="career-accordion-item"
                  uuid={this.makeUUID(role.name)}
                  key={this.makeUUID(role.name)}
                >
                  <AccordionItemHeading className="career-accordion-item-heading">
                    <AccordionItemButton>
                      <div className="title-wrapper">
                        <p className="h1 title">{role.name}</p>
                        <p className="desc">{role.location}</p>
                        <span className="toggle"></span>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="career-accordion-item-panel">
                    <CareerAccordionItem>
                      <div className="row__column width-25"></div>
                      <div className="row__column width-25 animation-content intro-content">
                        <div className="intro-content-wrapper">
                          <p>{role.description1}</p>
                        </div>
                        <Panel
                          header="Perks"
                          headerClass="career-paragraph-header left-panel-wrapper"
                          forceRender={true}
                        >
                          <div className="panel-wrapper left-panel-body">
                            {role.perks &&
                              role.perks.map(perk => (
                                <p key={this.makeUUID(perk.perk)}>
                                  <span className="dash">-</span>
                                  <span>{perk.perk}</span>
                                </p>
                              ))}
                          </div>
                        </Panel>
                      </div>
                      <div className="row__column width-50 animation-content desc-content">
                        <div className="right-top-content-wrapper">
                          <p className="desc">{role.description2}</p>
                        </div>
                        <Panel
                          header="Perks"
                          headerClass="career-paragraph-header left-mobile-panel-wrapper"
                          forceRender={true}
                        >
                          <div className="panel-wrapper left-mobile-panel-body">
                            {role.perks &&
                              role.perks.map(perk => (
                                <p key={this.makeUUID(perk.perk)}>
                                  <span className="dash">-</span>
                                  <span>{perk.perk}</span>
                                </p>
                              ))}
                          </div>
                        </Panel>
                        <Panel
                          header="Qualifications"
                          headerClass="career-paragraph-header"
                          forceRender={true}
                          className={`panel ${!role.qualifications && "hidden"}`}
                        >
                          <div className="panel-wrapper">
                            <p className="sub-title">
                              {role.qualifications
                                ? role.qualifications.subTitle
                                : ""}
                            </p>
                            {role.qualifications &&
                              role.qualifications.qualifications &&
                              role.qualifications.qualifications.map(qual => (
                                <p key={this.makeUUID(qual.qualification)}>
                                  <span className="dash">-</span>
                                  <span>{qual.qualification}</span>
                                </p>
                              ))}
                          </div>
                        </Panel>
                        <Panel
                          header="Responsibilities"
                          headerClass="career-paragraph-header"
                          forceRender={true}
                          className={`panel ${(!role.responsibilities || role.responsibilities.length === 0) && "hidden"}`}
                        >
                          <div className="panel-wrapper">
                            {role.responsibilities &&
                              role.responsibilities.map(res => (
                                <p key={this.makeUUID(res.responsibility)}>
                                  <span className="dash">-</span>
                                  <span>{res.responsibility}</span>
                                </p>
                              ))}
                          </div>
                        </Panel>
                        <Panel
                          header="Knowledge & Experience"
                          headerClass="career-paragraph-header"
                          forceRender={true}
                          className={`panel ${(!role.knowledges || role.knowledges.length === 0) && "hidden"}`}
                        >
                          <div className="panel-wrapper">
                            {role.knowledges &&
                              role.knowledges.map(know => (
                                <p key={this.makeUUID(know.knowledge)}>
                                  <span className="dash">-</span>
                                  <span>{know.knowledge}</span>
                                </p>
                              ))}
                          </div>
                        </Panel>
                        <Panel
                          header="Competencies"
                          headerClass="career-paragraph-header"
                          forceRender={true}
                          className={`panel ${(!role.competencies || role.competencies.length === 0) && "hidden"}`}
                        >
                          <div className="panel-wrapper">
                            {role.competencies &&
                              role.competencies.map(com => (
                                <p key={this.makeUUID(com.competency)}>
                                  <span className="dash">-</span>
                                  <span>{com.competency}</span>
                                </p>
                              ))}
                          </div>
                        </Panel>
                        <div className="panel-wrapper">
                          <p className="footer-desc">{role.mobileFooter}</p>
                        </div>
                      </div>
                    </CareerAccordionItem>
                  </AccordionItemPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>
      </section>
    );
  }
}

export default CareerAccordion;
