import React, { useEffect } from "react";
// import queryString from 'query-string';
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { navigate } from "gatsby-link";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Headline from "../components/text-elements/headline";
import ReadMoreTitle from "../components/text-elements/read-more-title";
import CareerAccordion from "../components/career-accordion/career-accordion";

const CareerPage = ({ data, pageContext }) => {
  const roleName = [pageContext.role];
  const { career } = data.takeshape;

  useEffect(() => {
    // Redirect to a new URL
    navigate("https://jobs.ashbyhq.com/half-helix");
  }, []);

  // if (
  //   (!career.enabled && process.env.GATSBY_ENVIRONMENT !== "staging") ||
  //   (!career.enableOnStaging && process.env.GATSBY_ENVIRONMENT === "staging")
  // ) {
  //   if (typeof window !== "undefined") {
  //     navigate("/");
  //   }
  // }

  return (
    <div className="career-page">
      <Helmet
        bodyAttributes={{
          class: "footer-offset alt-header",
        }}
      />
      <Layout headerClass="header--dark">
        <Seo title={career.pageTitle} description={career.pageDescription} />
        {/* <div className="career-headline-wrapper">
          <Headline headline={career.title} description={career.description} />
        </div>
        <CareerAccordion roles={career.roles} roleName={roleName} />
        <ReadMoreTitle
          title={career.missionTitle}
          primaryLinkPath={career.missionLink}
          secondaryLinkTitle={career.missionSecondaryTitle}
          secondaryLinkPath={career.missionSecondaryLink}
          category={career.missionCategory}
        /> */}
      </Layout>
    </div>
  );
};

export default CareerPage;

export const query = graphql`
  query {
    takeshape {
      career: getCareer {
        pageTitle
        pageDescription
        enabled
        enableOnStaging
        _id
        description
        missionCategory
        missionLink
        missionSecondaryLink
        missionSecondaryTitle
        missionTitle
        roles {
          competencies {
            competency
          }
          description1
          description2
          knowledges {
            knowledge
          }
          location
          mobileFooter
          name
          perks {
            perk
          }
          qualifications {
            qualifications {
              qualification
            }
            subTitle
          }
          responsibilities {
            responsibility
          }
        }
        title
      }
    }
  }
`;
