import React from "react";

const CareerAccordionItem = props => {
  return (
    <div>
      <div className="section section-columns gs-animate" id="section1">
        <div className="row">{props.children}</div>
      </div>
    </div>
  );
};

export default CareerAccordionItem;
